import { HttpClient } from '@angular/common/http';
import { IGbizConfig, IGbizUserInfo, IGbizToken } from '@interface/gbiz.interface';
import { Observable } from 'rxjs';

// DIコンテナから作らせない（使う前にConfigが必要なため、縛りを入れる）
export class AuthService {
  private config: IGbizConfig;
  private http: HttpClient;

  constructor(http: HttpClient, config: IGbizConfig) {
    this.http = http;
    this.config = config;
  }

  private randomString() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 30; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  async login(targetUrl?: string) {
    location.href =
      `${this.config.issuer}authorize` +
      `?client_id=${this.config.clientId}` +
      `&redirect_uri=${encodeURIComponent(this.config.redirectUri)}` +
      `&response_type=${this.config.responseType}` +
      `&scope=${encodeURIComponent(this.config.scope)}` +
      `&nonce=${this.randomString()}` +
      `&state=${encodeURIComponent(targetUrl)}`;
  }

  // GBizIDのログアウト用のAPIをコールする
  async logout() {
    // GビズIDのログアウト用ページをサーバーからロードする
    location.href = '/static/gbizid/logout.html';
  }

  // 【Codeフロー】アクセストークン取得リクエスト
  // GビズIDの当該APIにはAccess-Control-Allow-Originがないため、Heroku経由して呼び出す
  getAccessToken(authCode: string): Observable<IGbizToken> {
    return this.http.post<IGbizToken>('/api/gbiz/token', { code: authCode });
  }

  // 【Codeフロー】アクセストークン再取得リクエスト
  // GビズIDの当該APIにはAccess-Control-Allow-Originがないため、Heroku経由して呼び出す
  refreshAccessToken(refreshToken: string): Observable<IGbizToken> {
    return this.http.post<IGbizToken>('/api/gbiz/token/refresh', { refresh_token: refreshToken });
  }

  // 【Codeフロー】属性取得リクエスト
  // GビズIDの当該APIにはAccess-Control-Allow-Originがないため、Heroku経由して呼び出す
  getGbizUserInfo(accessToken: string): Observable<IGbizUserInfo> {
    return this.http.post<IGbizUserInfo>('/api/gbiz/user', { token: accessToken });
  }
}
