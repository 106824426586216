export const featureName = 'loading';

export interface State {
  zIndex: number;
  progress: number;
}

export const initialState: State = {
  zIndex: 99999,
  progress: 0,
};
