import { Injectable } from '@angular/core';
import { LoadingStoreModule } from './store/loading.store.module';
import { Store, select } from '@ngrx/store';
import * as LoadingActions from './store/loading.action';
import * as LoadingSelector from './store/loading.selector';
import { State } from './store/loading.state';

@Injectable({
  providedIn: LoadingStoreModule,
})
export class LoadingFacade {
  readonly zIndex$ = this.store.pipe(select(LoadingSelector.getZIndex));
  readonly progress$ = this.store.pipe(select(LoadingSelector.getProgress));

  constructor(private store: Store<State>) {}

  setZIndex(zIndex: number): void {
    this.store.dispatch(LoadingActions.setZIndex({ zIndex }));
  }

  setProgress(progress: number): void {
    this.store.dispatch(LoadingActions.setProgress({ progress }));
  }
}
