import { IGbizUserInfo } from '@interface/gbiz.interface';

export const featureName = 'auth';
export interface State {
  loggedIn: boolean;
  userProfile: IGbizUserInfo;
}
export const initialState: State = {
  loggedIn: false,
  userProfile: null,
};
