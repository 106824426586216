import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ApiCallSpinnerDriveInterceptorService } from './api-call-spinner-drive.intercepter.service';
import { TokenAppendGbizIdInterceptorService } from './gbiz-id/token-append.gbiz-id.intercepter.service';
import { MockInterceptorService } from './mock-interceptor.service';

const devHttpInterceptorProviders = environment.mock
  ? [{ provide: HTTP_INTERCEPTORS, useClass: MockInterceptorService, multi: true }]
  : [];

export const httpInterceptorProviders = [
  ...devHttpInterceptorProviders,
  // リクエストにはトークンを付与する
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenAppendGbizIdInterceptorService,
    multi: true,
  },
  // APIコールされるときはスピナーを回す
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiCallSpinnerDriveInterceptorService,
    multi: true,
  },
];
