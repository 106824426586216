import { createAction, props } from '@ngrx/store';
import { ICompanyInfo } from '@interface/company.interface';
import { IMirasapoPlusInfo } from '@interface/mirasapo-plus.interface';

export const setCompanyInfo = createAction(
  '[Company] Set Company Info',
  props<{ company: ICompanyInfo }>()
);

export const setMirasapoPlusInfo = createAction(
  '[Company] Set MirasapoPlus Info',
  props<{ mirasapoPlusInfo: IMirasapoPlusInfo }>()
);
