import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@domains/auth/auth.facade';

@Component({
  selector: 'app-logout-callback',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private auth: AuthFacade) {}

  ngOnInit() {
    console.log('[LogoutComponent] OnInit');

    // （SessionStorageがクリアされる前に実施）GビズIDのログアウトAPIの問題による暫定処理
    // GビズID側の対応が完了次第削除する
    if (this.auth.gBizConfig && this.auth.gBizConfig.issuer) {
      const gbizApiBaseUrl = new URL(this.auth.gBizConfig.issuer);
      window.open(`${gbizApiBaseUrl.origin}/app/logout`, '_blank');
    }

    // セッション変数他ログイン認証情報をクリアする
    this.auth.clear();

    // ルートにリダイレクトする
    this.router.navigate(['/']);
  }
}
