import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { LoadingComponent } from './loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingStoreModule } from '@domains/loading/store/loading.store.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [BrowserAnimationsModule, CommonModule, NgxSpinnerModule, TranslateModule, LoadingStoreModule],
  declarations: [LoadingComponent],
  exports: [LoadingComponent],
})
export class LoadingModule {}
