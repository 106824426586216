import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IGbizUserInfo } from '@interface/gbiz.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthFacade } from './auth.facade';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authFacade: AuthFacade) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('[AuthGuard] canActivate');

    return this.authFacade.userProfile$.pipe(
      map((userProfile: IGbizUserInfo) => {
        if (!userProfile || this.authFacade.isTimeout) {
          this.authFacade.login(state.url);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
