import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './loading.action';
import { State, initialState } from './loading.state';

const LoadingReducer = createReducer(
  initialState,
  on(Actions.setZIndex, (state, { zIndex }) => ({ ...state, zIndex })),
  on(Actions.setProgress, (state, { progress }) => ({ ...state, progress }))
);

export function loadingReducer(state: State, action: Action) {
  return LoadingReducer(state, action);
}
