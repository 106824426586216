export class ForceReloadException implements Error {
  name = 'ForceReloadException';
  message: string;
  stack?: string;

  constructor(msg?: string, err?: Error) {
    this.message = msg;

    if (err && err.stack) {
      this.stack = err.stack;
    }
  }
}
