export const PrefectureType = {
  '01': '北海道',
  '02': '青森県',
  '03': '岩手県',
  '04': '宮城県',
  '05': '秋田県',
  '06': '山形県',
  '07': '福島県',
  '08': '茨城県',
  '09': '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島県',
  47: '沖縄県',
} as const;
export type PrefectureType = typeof PrefectureType[keyof typeof PrefectureType];

/** 事業者情報 */
export interface ICompanyInfo {
  // gBiz連携項目そのまま
  /** アカウント管理番号 */
  sub: string;
  /** アカウント種別 */
  account_type: string;
  /** 法人番号/個人事業主管理番号 */
  corporate_number: string;
  /** 法人名/屋号 */
  name?: string;
  /** 法人名/屋号（英語表記） */
  en_name?: string;
  /** 本社所在地（市区町村）印鑑登録証明書住所（市区町村） */
  address1: string;
  /** 本社所在地（番地等）/印鑑登録証明書住所（番地等） */
  address2: string;
  /** 代表者名（姓）/個人事業主氏名（姓） */
  rep_last_nm: string;
  /** 代表者名（名）/個人事業主氏名（名） */
  rep_first_nm: string;
  /** 代表者名フリガナ/個人事業主氏名 フリガナ（姓） */
  rep_last_nm_kana: string;
  /** 代表者名フリガナ/個人事業主氏名 フリガナ（名） */
  rep_first_nm_kana: string;
  /** 代表者生年月日/個人事業主生年月日 yyyyMMdd */
  birthday_ymd?: string;
  /** アカウント利用者氏名（姓） */
  user_last_nm: string;
  /** アカウント利用者氏名（名） */
  user_first_nm: string;
  /** アカウント利用者氏名フリガナ（姓） */
  user_last_nm_kana: string;
  /** アカウント利用者氏名フリガナ（名） */
  user_first_nm_kana: string;
  /** 連絡先郵便番号 */
  user_post_code: string;
  /** 連絡先住所（市区町村） */
  user_address1: string;
  /** 連絡先住所（番地等） */
  user_address2: string;
  /** 連絡先住所（マンション名等） */
  user_address3?: string;
  /** 会社部署名/部署名 */
  user_department?: string;
  /** 連絡先電話番号 */
  user_tel_no_contact?: string;
  /** 利用者生年月日 */
  user_birthday_ymd?: string;
  /** 該当ユーザの内部委任情報として登録されている RP の client_id */
  mandate_info?: Array<{ client_id: string }>;
  /** アカウント ID（メールアドレス） */
  user_email: string;

  // gBizから変換する項目
  /** 事業形態 */
  corp_type: '法人' | '個人事業主';
  /** 親アカウント管理番号 */
  parent_id?: string;
  /** 本社所在地（都道府県）/印鑑登録証明書住所（都道府県） */
  prefecture_name: PrefectureType;
  /** 連絡先住所（都道府県） */
  user_prefecture_name: PrefectureType;

  // 追加事業者情報
  /** 法人名（カナ）/屋号（カナ） */
  nameKana?: string;
  /** 法人事業形態詳細 */
  corporateTypeDetail?: string;
  /** 代表者役職/個人事業主役職 */
  representativePost?: string;
  /** 法人/個人事業主の営む主な事業及びその内容 */
  businessDescription?: string;
  /** 設立年月日（法人/個人事業主） */
  foundationDate?: string;
  /** 事業規模 */
  businessScale?: string;
  /** 従業員数 */
  employeesNumber?: number;
  /** 法人/個人事業主の営む主な事業（業種：大分類） */
  category?: string;
  /** 法人/個人事業主の営む主な事業（業種：中分類） */
  subcategory?: string;
  /** 法人/個人事業主の営む主な事業（業種：小分類） */
  furtherSubcategory?: string;
  /** 資本金（出資金） */
  capitalStock?: number;
  /** 決算年月日 */
  closingDate?: string;
  /** 流動資産 */
  totalCurrentAssets?: number;
  /** 流動負債 */
  totalCurrentLiabilities?: number;
  /** 自己資本 */
  totalNetAssets?: number;
  /** 総資本 */
  totalAssets?: number;
  /** 売上高 */
  amountOfSales?: number;
  /** 原価 */
  costOfSales?: number;
  /** 流動比率 */
  totalCurrentAssetsRatio?: number;
  /** 自己資本比率 */
  totalNetAssetsRatio?: number;
  /** 粗利益 */
  grossProfit?: number;
  /** 労働生産性 */
  laborProductivity?: number;
}
