import { ICompanyInfo } from '@interface/company.interface';
import { IMirasapoPlusInfo } from '@interface/mirasapo-plus.interface';

export const featureName = 'company';

export interface State {
  loading: boolean;
  company: ICompanyInfo;
  mirasapoPlusInfo: IMirasapoPlusInfo;
}

export const initialState: State = {
  loading: false,
  company: null,
  mirasapoPlusInfo: null,
};
