<div class="dialog-outline">
  <div class="message-dialog-title-bar">
    <h2 mat-dialog-title class="title">{{ dialogData.title | translate }}</h2>
      <button mat-icon-button class="buntton-close" (click)="handleClick($event, dialogData.cancel)">
        <mat-icon>close</mat-icon>
      </button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content class= "message-dialog-content"> {{ dialogData.message | translate:dialogData.messageParam }} </div>

  <mat-dialog-actions align="center">
    <button *ngIf="dialogData.callback" mat-button (click)="handleClick($event, dialogData.callback)">
      {{ dialogData.callback.label | translate }}
    </button>
    <button mat-button (click)="handleClick($event, dialogData.cancel)">
      {{ dialogData.cancel.label | translate }}
    </button>
  </mat-dialog-actions>
</div>