import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyStoreModule } from './store/company.store.module';
import { ICompanyInfo } from '@interface/company.interface';
import { IMirasapoPlusInfo } from '@interface/mirasapo-plus.interface';

@Injectable({
  providedIn: CompanyStoreModule,
})
export class CompanyApiService {
  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {}

  async saveCompanyInfo(company: ICompanyInfo): Promise<ICompanyInfo> {
    const url = `${this.apiUrl}/company`;
    // Note: Muleには配列で送信する
    return await this.http
      .post<ICompanyInfo>(url, [company])
      .toPromise();
  }

  async getMirasapoPlusInfo(employeesNumber: number): Promise<IMirasapoPlusInfo> {
    const url = `${this.apiUrl}/company/mirasapo-plus/get`;
    return await this.http
      .post<IMirasapoPlusInfo>(url, { employeesNumber: employeesNumber })
      .toPromise();
  }
}
