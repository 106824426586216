import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './company.action';
import { State, initialState } from './company.state';

const CompanyReducer = createReducer(
  initialState,
  on(Actions.setCompanyInfo, (state, { company }) => ({
    ...state,
    company: state.company ? { ...state.company, ...company } : company,
  })),
  on(Actions.setMirasapoPlusInfo, (state, { mirasapoPlusInfo }) => ({
    ...state,
    mirasapoPlusInfo: mirasapoPlusInfo,
  }))
);

export function companyReducer(state: State, action: Action) {
  return CompanyReducer(state, action);
}
