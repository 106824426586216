import { Routes } from '@angular/router';
import { CallbackComponent } from '@pages/callback/callback.component';
import { LogoutComponent } from '@pages/logout/logout.component';
import { createMetaTag } from '@shared/services/meta/meta.constant';
import { AuthGuard } from '@domains/auth/auth.guard';

export const rootRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@pages/navigation/navigation.module').then((m) => m.NavigationModule),
  },
  // 通知文書プレビュー
  {
    path: 'notification/:notificationDocumentSfid',
    loadChildren: () =>
      import('@pages/notification-preview/notification-preview.module').then(
        (m) => m.NotificationPreviewModule
      ),
    canActivate: [AuthGuard],
    data: createMetaTag('通知文書の文面表示'),
  },
  // Callback設定(開発用)
  {
    path: 'callback',
    redirectTo: 'gbizid/callback',
    pathMatch: 'full',
  },
  {
    path: 'gbizid/callback',
    component: CallbackComponent,
  },
  // GビズIDのログアウトcallback
  {
    path: 'gbizid/logout',
    component: LogoutComponent,
  },
];
