import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MessageDialogComponent } from './message.dialog';

@NgModule({
  declarations: [MessageDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [MessageDialogComponent],
})
export class MessageDialogModule {}
