<ng-container *ngIf="zIndex$ | async as zIndex">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
    [zIndex]="zIndex"
    >
    <p style="color: white"> {{ 'BASE.LOADING' | translate }} </p>
  </ngx-spinner>
</ng-container>
