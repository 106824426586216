import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@organisms/loading/loading.module';
import { LogoutComponent } from './logout.component';

@NgModule({
  imports: [LoadingModule, RouterModule],
  declarations: [LogoutComponent],
})
export class LogoutModule {}
